//

import axios from 'axios';
import { useSnackbar } from 'notistack';
//
import useAuth from '../useAuth';

import { endpoints } from '../../configs/api';
import { getAppCheckToken } from '../../configs/AppCheck';
// -----------------------------------------------

const typeJson = { 'Content-Type': 'application/json' };

// -----------------------------------------------

const useApi = () => {
    const { enqueueSnackbar } = useSnackbar();
    const { auth } = useAuth();

    // after session expiry reset local storage
    const resetLocalStorage = () => {
        // console.warn("21 resetLocalStorage");

        // clear only compulsory keys
        window.localStorage.removeItem("auth");
        window.localStorage.removeItem("facilities");
        window.localStorage.removeItem("selectedFacility");

        // window.localStorage.removeItem("salesTypes");
        // window.localStorage.removeItem("dataInputTypes");
        // window.localStorage.removeItem("paymentTypes");

        window.localStorage.removeItem("fcmDeviceToken");

        // redirect
        setTimeout(() => {
            // console.warn("38/useApi reloading");
            window.location.reload();
            // navigate(PATH_AUTH.login);
        }, 20);
    }

    const fetchResponse = async (
        url,
        method = "GET",
        data = {},
        headers = typeJson,
    ) => {
        if (process.env.REACT_APP_ENV === 'staging' || process.env.REACT_APP_ENV === 'prod') {
            const appCheckToken = await getAppCheckToken();
            if (appCheckToken) {
                headers = {...headers, 'x-oneqr-app-token': appCheckToken?.token}
            }
        }

        if (auth && auth?.token) {
            headers = { ...headers, 'Authorization': `Bearer ${auth?.token}` }
        }

        let rawResponse;
        try {
            rawResponse = await axios({
                method,
                url,
                headers,
                data,
                withCredentials: true
            })

            return { data: rawResponse.data, error: false, status: rawResponse?.status };
        }

        catch (error) {
            const errStatus = error?.response?.status || null;

            if (!errStatus) {
                // console.log(error);
                return { data: error, error: true, status: errStatus };
            }
            if (errStatus === 401) {
                // logout only if status code 401
                // clear application data (in memory and storage)
                resetLocalStorage();
                // resetAuth();
                return { data: error.response.data, error: true, status: errStatus };
            }
            if (errStatus === 403) {
                // reload the application to reinitialize app check and re-captcha
                window.location.reload();            }
            if ((errStatus === 400) && error.response.statusText === "Bad Request") {
                // some times data is just string
                // TODO : can we get standardised json error message
                const { data } = error.response;
                enqueueSnackbar(typeof data === 'string' ? data : data.message, { variant: 'error', style: { whiteSpace: 'pre-line' }, persist: true });

                return { data: error.response.data, error: true, status: errStatus };
            }
            return { data: error.response.data, error: true, status: errStatus };
        }
    }



    // move to useAuth
    const loginApi = async (data) => axios.post(endpoints.login, JSON.stringify(data), {
        headers: typeJson,
        "credentials": 'include',
        withCredentials: true
    })


    const uploadFile = async (url, file, type, name) => {
        const data = new FormData();
        data.append('file', file);
        data.append('type', type);
        data.append('name', name);

        return fetchResponse(url, "POST", data);
    }


    // helper function to download file, can be reused
    const downloadFile = async (endpoint, url) => fetch(endpoint, {
        method: 'POST',
        headers: {},
        body: JSON.stringify({ url }),
        credentials: 'include'
    })
        .then((response) => {
            if (!response.ok) throw new Error(response.status);
            else {
                const filename = response.headers.get('Content-Disposition').split('filename=')[1];
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            };
        })
        .catch((error) => {
            enqueueSnackbar('Failed to download', { variant: 'error' });

            console.error('466 Error:', error);
        });


    // helper function to download file, can be reused
    const downloadDoc = async (endpoint, payload) => fetch(endpoint, {
        method: 'POST',
        headers: {},
        body: JSON.stringify(payload),
        credentials: 'include'
    })
        .then((response) => {
            if (!response.ok) throw new Error(response.status);
            else {
                const filename = response.headers.get('Content-Disposition').split('filename=')[1];
                response.blob().then(blob => {
                    const url = window.URL.createObjectURL(blob);
                    const a = document.createElement('a');
                    a.href = url;
                    a.download = filename;
                    a.click();
                });
            };
        })
        .catch((error) => {
            enqueueSnackbar('Failed to download', { variant: 'error' });

            console.error('466 Error:', error);
        });


    return {
        fetchResponse,
        loginApi,
        // logoutApi,
        uploadFile,
        downloadFile,
        downloadDoc
    }
}



export default useApi;
